<div class="mx-auto border-2 bg-white minWidthLg max-w-7xl p-4">
  <el-button class="absolute right-0" type="primary" @click="loadMore" size="mini" v-if="is_load_more"
    >Tải thêm</el-button
  >
  <div class="header relative">
    <h5>Tên người nộp thuế: {{ companyName }}</h5>
    <h5>Mã số thuế: {{ dataTableOut.MST }}</h5>
    <div class="titleTable text-center mt-2 border-2 border-black py-2">
      <p class="font-bold">
        BẢNG KÊ HÓA ĐƠN, CHỨNG TỪ HÀNG HÓA, DỊCH VỤ BÁN RA
      </p>
    </div>
    <p class="text-center font-normal mt-1 text-sm">
      Kỳ kê khai thuế: {{ dataTableOut.period_str }}
    </p>
    <div class="text-right">
      <span>Đơn vị tiền: {{ donViTien }}</span>
    </div>
  </div>

  <div class="dataTable hscroll">
    <table class="text-center w-full text-sm">
      <thead class="bg-yellow-400 sticky top-0">
        <tr>
          <th class="w-12" rowspan="2">STT</th>
          <th class="w-80" colspan="4">Hóa đơn, chứng từ bán ra</th>
          <th class="w-52" rowspan="2">Tên người mua</th>
          <th class="w-28" rowspan="2">Mã số thuế người mua</th>
          <th rowspan="2">Mặt hàng</th>
          <th class="w-40" rowspan="2">Doanh số bán chưa có thuế</th>
          <th class="w-24" rowspan="2">Thuế suất (%)</th>
          <th class="w-40" rowspan="2">Thuế GTGT</th>
          <th class="w-24" rowspan="2">Ghi chú</th>
        </tr>

        <tr>
          <th>Mã hóa đơn</th>
          <th>Ký hiệu hóa đơn</th>
          <th>Số hóa đơn</th>
          <th>Ngày lập hóa đơn</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>[1]</td>
          <td>[2]</td>
          <td>[3]</td>
          <td>[4]</td>
          <td>[5]</td>
          <td>[6]</td>
          <td>[7]</td>
          <td>[8]</td>
          <td>[9]</td>
          <td>[10]</td>
          <td>[11]</td>
          <td>[12]</td>
        </tr>

        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            1. Hàng hóa, dịch vụ không chịu thuế giá trị gia tăng(GTGT):
          </td>
        </tr>

        <tr
          v-for="(item, index) in dataTableOut.invoices_revenue_no_tax_kkknt"
          :key="index"
        >
          <td>{{index +1}}</td>
          <td>{{item.form}}</td>
          <td>{{item.serial}}</td>
          <td>{{item.number}}</td>
          <td>{{ formatDate(item.issued_date)}}</td>
          <td class="text-left">{{item.buyer_name}}</td>
          <td>{{item.buyer_tax_code}}</td>
          <td class="text-left"><p class="line-clamp-6">{{item.products_name}}</p></td>
          <td>
            <input-money
              v-model.lazy="item.sum_products_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ convertTaxNumberToText(item.products__vat, item.products__is_no_tax) }}</td>
          <td>
            <input-money
              v-model.lazy="item.sum_products_vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ getInvoiceStatus(item) }}</td>
        </tr>

        <tr v-if="dataTableOut.invoices_revenue_no_tax_kkknt.length >= 500">
          <td colspan="12" class="text-yellow-700">
            <p class="p-2 font-bold"><i class="el-icon-warning-outline mr-2"/>Dữ liệu có thể thiếu, vui lòng tải tệp để xem dữ liệu đầy đủ</p>
          </td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.revenue_no_tax_kkknt"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td colspan="3"></td>
        </tr>

        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            2. Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 0%:
          </td>
        </tr>

        <tr
          v-for="(item, index) in dataTableOut.invoices_revenue_0"
          :key="index"
        >
          <template v-if="!item.products__tchat || item.products__tchat != 'Ghi chú, diễn giải'">
            <td>{{index +1}}</td>
            <td>{{item.form}}</td>
            <td>{{item.serial}}</td>
            <td>{{item.number}}</td>
            <td>{{ formatDate(item.issued_date)}}</td>
            <td class="text-left">{{item.buyer_name}}</td>
            <td>{{item.buyer_tax_code}}</td>
            <td class="text-left"><p class="line-clamp-6">{{item.products_name}}</p></td>
            <td>
              <input-money
                v-model.lazy="item.sum_products_amount"
                class="money"
                :invoiceStyle="true"
                :table="true"
                :decima="0"
              />
            </td>
            <td>{{ convertTaxNumberToText(item.products__vat, item.products__is_no_tax) }}</td>
            <td>
              <input-money
                v-model.lazy="item.sum_products_vat_amount"
                class="money"
                :invoiceStyle="true"
                :table="true"
                :decima="0"
              />
            </td>
          <td>{{ getInvoiceStatus(item) }}</td>
          </template>
        </tr>
        <tr v-if="dataTableOut.invoices_revenue_0.length >= 500">
          <td colspan="12" class="text-yellow-700">
            <p class="p-2 font-bold"><i class="el-icon-warning-outline mr-2"/>Dữ liệu có thể thiếu, vui lòng tải tệp để xem dữ liệu đầy đủ</p>
          </td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.revenue_0"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td colspan="3"></td>
        </tr>

        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            3. Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 5%:
          </td>
        </tr>

        <tr
          v-for="(item, index) in dataTableOut.invoices_revenue_5"
          :key="index"
        >
          <td>{{index +1}}</td>
          <td>{{item.form}}</td>
          <td>{{item.serial}}</td>
          <td>{{item.number}}</td>
          <td>{{ formatDate(item.issued_date)}}</td>
          <td class="text-left">{{item.buyer_name}}</td>
          <td>{{item.buyer_tax_code}}</td>
          <td class="text-left"><p class="line-clamp-6">{{item.products_name}}</p></td>
          <td>
            <input-money
              v-model.lazy="item.sum_products_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ convertTaxNumberToText(item.products__vat, item.products__is_no_tax) }}</td>
          <td>
            <input-money
              v-model.lazy="item.sum_products_vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ getInvoiceStatus(item) }}</td>
        </tr>
        <tr v-if="dataTableOut.invoices_revenue_5.length >= 500">
          <td colspan="12" class="text-yellow-700">
            <p class="p-2 font-bold"><i class="el-icon-warning-outline mr-2"/>Dữ liệu có thể thiếu, vui lòng tải tệp để xem dữ liệu đầy đủ</p>
          </td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.revenue_5"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>5%</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.vat_amount_5"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
        </tr>

        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            4. Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 8%:
          </td>
        </tr>
        <tr
          v-for="(item, index) in dataTableOut.invoices_revenue_only_8"
          :key="index"
        >
          <td>{{index +1}}</td>
          <td>{{item.form}}</td>
          <td>{{item.serial}}</td>
          <td>{{item.number}}</td>
          <td>{{ formatDate(item.issued_date)}}</td>
          <td class="text-left">{{item.buyer_name}}</td>
          <td>{{item.buyer_tax_code}}</td>
          <td class="text-left"><p class="line-clamp-6">{{item.products_name}}</p></td>
          <td>
            <input-money
              v-model.lazy="item.sum_products_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ convertTaxNumberToText(item.products__vat, item.products__is_no_tax) }}</td>
         <td>
            <input-money
              v-model.lazy="item.sum_products_vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ getInvoiceStatus(item) }}</td>
        </tr>
        <tr v-if="dataTableOut.invoices_revenue_only_8.length >= 500">
          <td colspan="12" class="text-yellow-700">
            <p class="p-2 font-bold"><i class="el-icon-warning-outline mr-2"/>Dữ liệu có thể thiếu, vui lòng tải tệp để xem dữ liệu đầy đủ</p>
          </td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.revenue_only_8"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>8%</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.vat_amount_only_8"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="12" class="text-left bg-yellow-400 font-bold">
            5. Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 10%:
          </td>
        </tr>
        <tr
          v-for="(item, index) in dataTableOut.invoices_revenue_only_10"
          :key="index"
        >
          <td>{{index +1}}</td>
          <td>{{item.form}}</td>
          <td>{{item.serial}}</td>
          <td>{{item.number}}</td>
          <td>{{ formatDate(item.issued_date)}}</td>
          <td class="text-left">{{item.buyer_name}}</td>
          <td>{{item.buyer_tax_code}}</td>
          <td class="text-left"><p class="line-clamp-6">{{item.products_name}}</p></td>
          <td>
            <input-money
              v-model.lazy="item.sum_products_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ convertTaxNumberToText(item.products__vat, item.products__is_no_tax) }}</td>
         <td>
            <input-money
              v-model.lazy="item.sum_products_vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{ getInvoiceStatus(item) }}</td>
        </tr>

        <tr v-if="dataTableOut.invoices_revenue_only_10.length >= 500">
          <td colspan="12" class="text-yellow-700">
            <p class="p-2 font-bold"><i class="el-icon-warning-outline mr-2"/>Dữ liệu có thể thiếu, vui lòng tải tệp để xem dữ liệu đầy đủ</p>
          </td>
        </tr>

        <tr class="bg-green-200">
          <td colspan="8" class="text-left">Tổng</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.revenue_only_10"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>10%</td>
          <td>
            <input-money
              v-model.lazy="dataTableOut.vat_amount_only_10"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
        </tr>

      </tbody>
    </table>

    <div class="footTable">
      <div class="mt-5 flex items-center text-sm">
        <h5 class="w-1/2">
          Tổng doanh thu hàng hóa, dịch vụ bán ra chịu thuế GTGT:
        </h5>
        <div class="border-black border-2 w-1/2 py-1 px-2 text-right 2xl:mr-56">
          <input-money
            v-model.lazy="dataTableOut.total_revenue"
            class="money"
            :invoiceStyle="true"
            :table="true"
            :decima="0"
          />
        </div>
      </div>
      <div class="mt-5 flex items-center text-sm">
        <h5 class="w-1/2">Tổng số thuế GTGT của hàng hóa, dịch vụ bán ra:</h5>
        <div class="border-black border-2 w-1/2 py-1 px-2 text-right 2xl:mr-56">
          <input-money
            v-model.lazy="dataTableOut.vat_output"
            class="money"
            :invoiceStyle="true"
            :table="true"
            :decima="0"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="sign flex justify-end mt-10 mb-52 text-sm font-bold">
    <div class="text-center">
      <div class="date text-right">
        ...............,ngày..........tháng..........năm..........
      </div>
      <h5 class="mt-3">
        <span class="uppercase">Người nộp thuế </span>hoặc <br /><span
          class="uppercase"
        >
          ĐẠI DIỆN HỢP PHÁP CỦA NGƯỜI NỘP THUẾ
        </span>
        <br />
        <span>Ký tên, đóng dấu (ghi rõ họ tên và chức vụ)</span>
      </h5>
    </div>
  </div>
</div>
